@font-face {
font-family: '__SulSans_65fa96';
src: url(/_next/static/media/fd8c740be372a333-s.p.otf) format('opentype');
font-display: block;
font-weight: 400;
}

@font-face {
font-family: '__SulSans_65fa96';
src: url(/_next/static/media/acb2f102e97492c7-s.p.otf) format('opentype');
font-display: block;
font-weight: 500;
}

@font-face {
font-family: '__SulSans_65fa96';
src: url(/_next/static/media/7cc70b7de2d9d3cc-s.p.otf) format('opentype');
font-display: block;
font-weight: 300;
}

@font-face {
font-family: '__SulSans_65fa96';
src: url(/_next/static/media/9dc7851aab2c94f9-s.p.otf) format('opentype');
font-display: block;
font-weight: 700;
}@font-face {font-family: '__SulSans_Fallback_65fa96';src: local("Arial");ascent-override: 90.54%;descent-override: 26.12%;line-gap-override: 0.00%;size-adjust: 106.03%
}.__className_65fa96 {font-family: '__SulSans_65fa96', '__SulSans_Fallback_65fa96'
}.__variable_65fa96 {--font-sul-sans: '__SulSans_65fa96', '__SulSans_Fallback_65fa96'
}

@font-face {
font-family: '__ModernGothic_8f1b43';
src: url(/_next/static/media/269a5e94cc933663-s.p.otf) format('opentype');
font-display: block;
font-weight: 400;
}

@font-face {
font-family: '__ModernGothic_8f1b43';
src: url(/_next/static/media/71823579883ee2e2-s.p.otf) format('opentype');
font-display: block;
font-weight: 300;
}@font-face {font-family: '__ModernGothic_Fallback_8f1b43';src: local("Arial");ascent-override: 89.60%;descent-override: 24.18%;line-gap-override: 0.00%;size-adjust: 105.47%
}.__className_8f1b43 {font-family: '__ModernGothic_8f1b43', '__ModernGothic_Fallback_8f1b43'
}.__variable_8f1b43 {--font-modern-gothic: '__ModernGothic_8f1b43', '__ModernGothic_Fallback_8f1b43'
}

